import Rollbar from 'rollbar';
import { merge, always } from 'ramda';

const defaults = {
    getPayload: always(null),
    domains: ['.*?']
};

export const pushRollbarError = (error, info) => {
    if (process.env.NODE_ENV === 'production') {
        Rollbar.configure({
            payload: {
                info
            }
        });
        Rollbar.error(error.message, error);
    }

    console.error(error);
}

export default function (options) {
    options = merge(defaults, options);

    window._version = {
        version: options.version,
    };

    window._rollbarConfig = {
        accessToken: options.token,
        captureUncaught: true,
        payload: {
            environment: options.environment,
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: options.version,
                    guest_uncaught_frames: true
                }
            }
        },
        hostWhiteList: [window.location.hostname]
    };

    Rollbar.init(window._rollbarConfig);
}

import React from 'react';

export const WRONG_TOKEN = (
    <div>
        Некорректный токен
    </div>
);
export const WRONG_LINK = 'Некорректная ссылка';
export const WRONG_ID = 'Некорректная вакансия';
export const NOT_FOUND = 'Не найдено';
export const SERVER_ERROR = 'Ошибка сервера';

import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import { getVacancyId } from '../../utils/params';
import { getVacancy } from '../../actions/asyncActions';
import withSuccessPage from '../hocs/withSuccessPage';

class ApplicationSuccess extends Component {
    render() {
        return (
            <div className="wrap-sidebar success-wrap">
                <div className="head-box">
                    <div className="title" style={{marginBottom: 24}}>Спасибо за Ваш отклик. </div>
                    <div className="desc">Рекрутер свяжется с Вами в течение 3-х дней. При возникновении вопросов обращайтесь на: <a href="mailto:recruitment@nrbcapital.ru">recruitment@nrbcapital.ru</a></div>
                </div>
            </div>
        );
    }
}

export default withSuccessPage(withAsyncActions({
    getVacancy: getVacancy
        .withPayload(getVacancyId)
})(ApplicationSuccess));

import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import RecommendedLanding from './pages/RecommendedLanding';
import ApplicationSuccess from './pages/ApplicationSuccess';

import Layout from './Layout';
import NoMatch from './NoMatch';

class Routes extends Component {
    render() {
        return (
            <Layout>
                <Switch>
                    <Route path='/' exact component={NoMatch} />
                    <Route path='/recommended/:id/:token/success' component={ApplicationSuccess} />
                    <Route path='/recommended/:id/:token' component={RecommendedLanding} />
                    <Route component={NoMatch} />
                </Switch>
            </Layout>
        );
    }
}

export default withRouter(Routes);

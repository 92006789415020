import axios from 'axios';
import { omit } from 'ramda';

import { BASE_URL } from '../constants/urls';

axios.defaults.timeout = 5 * 60 * 1000;

export function setBaseUrl(base = BASE_URL) {
    axios.defaults.baseURL = base;
}

export function setLanguage(language = 'ru') {
    axios.defaults.headers.common['accept-language'] = language;
}

export function addDefaultHeader(header, value) {
    axios.defaults.headers.common[header] = value;
}

export function removeDefaultHeader(header) {
    omit([header], axios.defaults.headers.common);
}

export function getDefaultHeader(header) {
    return axios.defaults.headers.common[header];
}

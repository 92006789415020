import React, { Component } from 'react';
import { Layout } from 'antd';

import ScrollToTop from './ScrollToTop';

const { Content } = Layout;

export default class LayoutComponent extends Component {
    render() {
        return (
            <Layout className='layout'>
                <Content>
                  <div className="container">
                    <ScrollToTop>
                        <div className='site-layout-content'>{this.props.children}</div>
                    </ScrollToTop>
                  </div>
                </Content>
            </Layout>
        );
    }
}

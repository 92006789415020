import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Spin, Row, Col, Radio } from 'antd';
import { compose, prop, path, pathOr, propEq, find, isNil, assocPath } from 'ramda';
import Form from '@experium/findy-form';

import { REFERRAL_DOMAIN } from '../../constants/domain';
import { TRANSLATIONS } from '../../constants/translation';
import { getVacancy, getDomain, getReferal, postReferalApplicant } from '../../actions/asyncActions';
import NetworkError from '../NetworkError';

class RecommendedLanding extends Component {
  state = {
    value: null,
    accepted: null,
  };

  onChangeValue = ({ target: { value } }) => this.setState({ value });

  setAccepted = () => {
    if (this.state.value === 'next') {
      this.setState({ accepted: true });
      window.scrollTo(0, 0);
    } else {
      window.location.href = 'https://www.acptl.ru/';
    }
  }

  onSubmit = values => this.props.postReferalApplicant.dispatch(values, ({
    token: path(['match', 'params', 'token'], this.props),
  }));

  render() {
    const { accepted, value } = this.state;
    const { postReferalApplicant, getReferal: { data : referal, meta : referalMeta }, getVacancy: { data, meta }, getDomain: { data : domainData, meta : domainMeta } } = this.props;
    const company = path(['_relations', 'company'], data);
    const allowFileExtensions = path(['_relations', 'company', 'companySettings', 'allowFileExtensions'], data);

    if (!referalMeta.success || !meta.success || !domainMeta.success) {
      return referalMeta.error || domainMeta.error || meta.error ? (
          <NetworkError error={referalMeta.error || domainMeta.error || meta.error} isLink={referalMeta.error} />
      ) : (
          <div className="load-box"><Spin /></div>
      );
    }

    return <Fragment>
      { isNil(accepted) && !meta.success ? null : accepted ?
          <Fragment>
            { postReferalApplicant.meta.success ? (
                <div className="head-box">
                  <div className="title">Спасибо!</div>
                  <div className="desc">Теперь у&nbsp;нас есть все подробности. Мы&nbsp;будем на&nbsp;связи, если нам понадобится дополнительная информация о&nbsp;вас. Следите за&nbsp;своим почтовым ящиком.</div>
                </div>
            ) : domainMeta.success && (
                <div>
                  <div className="head-box">
                    <div className="desc">
                      Вас рекомендовали на вакансию:
                    </div>
                    <div className="title">{path(['title'], data)}</div>
                  </div>
                  <Fragment>
                    <Form
                        initialValues={path(['form'], referal)}
                        fields={compose(
                            assocPath([5, 'label'], 'Фамилия'),
                            assocPath([6, 'label'], 'Имя'),
                            assocPath([7, 'label'], 'Отчество'),
                            assocPath([8, 'label'], 'Номер мобильного телефона'),
                            assocPath([3, 'label'], 'Электронная почта'),
                            assocPath([9, 'label'], 'Резюме'),
                            pathOr([], ['_relations', 'domainFormPreset', 'questions']),
                        )(domainData)}
                        formRender={this.formRender}
                        onSubmit={this.onSubmit}
                        opd={pathOr([], ['pdaSettings', 'text'], company)}
                        opdSettings={pathOr([], ['pdaSettings'], company)}
                        language={'ru'}
                        postFileUrl={`/api/landing/public/file/${data.id}`}
                        getFileUrl={id => `${process.env.REACT_APP_LOCAL_FILEURL || window.location.origin}/api/file/${id}`}
                        serverErrors={postReferalApplicant.meta.error}
                        submitting={postReferalApplicant.meta.pending}
                        allowFileExtensions={allowFileExtensions}
                        translations={TRANSLATIONS}
                    />
                  </Fragment>
                </div>
            )}
          </Fragment> :
          <div>
            <div className="head-box">
              <div className='text-box' xs={24} lg={12}>
                <div>
                  {/* <h1>Hi, {path(['form', 'firstName'], referal)}</h1> */}
                  <div className="desc">
                    {path(['form', 'LastNameArovana'], referal)} {path(['form', 'FirstNameArovana'], referal)} рекомендовал вас на вакансию
                  </div>
                  <div className="title">{path(['title'], data)}</div>
                  <div className="text-required">Нам необходимо ваше одобрение, чтобы продолжить рассмотрение вашего заявления</div>
                </div>
              </div>
              {path(['description'], data) && <div className="vacancy-wrap">
                <h3>Описание вакансии</h3>
                {(['_relations', 'cities', 'length'], data) ? <div><strong>Город: </strong>{(path(['_relations', 'cities'], data) || []).map(city => (
                  <span key={city.id}>{city.name}</span>
                ))}</div> : null}
                <br />
                <div dangerouslySetInnerHTML={{ __html: path(['description'], data) }}></div>
              </div>}
              <div className="box small-box select-statement">
                <label className="label">Выберите подходящее утверждение ниже</label>
                <Row gutter={{ xs: 0, sm: 16, md: 24 }}>
                  <Col xs={24} lg={24}>
                    <Radio.Group
                        options={[
                          { label: 'Мне интересна вакансия и я готов заполнить данные', value: 'next' },
                          { label: 'Мне неинтересна эта вакансия', value: 'dont' },
                        ]}
                        onChange={this.onChangeValue}
                        value={value}
                        optionType="button"
                    />
                  </Col>
                </Row>
              </div>
              <div className="box small-box" style={{ paddingTop: 0}}>
                <Row gutter={{ xs: 0, sm: 16, md: 24 }}>
                  <Col xs={24} lg={12}>
                    <button
                        className='index__formBtn'
                        disabled={!value}
                        type='primary'
                        onClick={() => this.setAccepted()}
                        data-analytics-link-name="next"
                        data-analytics-content-type="nav/paginate"
                        data-analytics-template-zone="body"
                        data-analytics-module-name="experium-module"
                    >Далее</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
      }
    </Fragment>;
  }

  formRender = ({ fields, renderField }) => (
      <Fragment>
      <div>
        <div className='box'>
            <Row>
              <Col span={24}>
                <h2>ПЕРСОНАЛЬНАЯ ИНФОРМАЦИЯ</h2>
              </Col>
            </Row>
            <Row gutter={{ xs: 0, sm: 16, md: 24 }}>
              <Col xs={24} lg={8}>
                {renderField(fields[5])}
              </Col>
              <Col xs={24} lg={8}>
                {renderField(fields[6])}
              </Col>
              <Col xs={24} lg={8}>
                {renderField(fields[7])}
              </Col>
            </Row>
          </div>
          <div className='box gray-box'>
            <Row>
              <Col span={24}>
                <h2>КОНТАКТНАЯ ИНФОРМАЦИЯ</h2>
              </Col>
            </Row>
            <Row gutter={{ xs: 0, sm: 16, md: 24 }}>
              <Col xs={24} lg={12}>
                {renderField(fields[8])}
              </Col>
              <Col xs={24} lg={12}>
                {renderField(fields[3])}
              </Col>
            </Row>
          </div>
        </div>
        <div className="box last-box">
          <Row gutter={{ xs: 0, sm: 16, md: 40 }}>
            <Col xs={24} lg={12}>
              {renderField(fields[9])}
            </Col>
            <Col xs={24} lg={24}>
              {renderField(fields[10])}
            </Col>
          </Row>
        </div>
      </Fragment>
  )
}
export default withAsyncActions({
  postReferalApplicant: postReferalApplicant
      .withOptions()
      .withSuccessHandler(({ history, location }) => {
        history.push(`${location.pathname}/success`.replace('//', '/'));
      }),
  getReferal: getReferal
      .withPayload(path(['match', 'params', 'token']))
      .withOptions({ dispatchOnMount: true }),
  getVacancy: getVacancy
      .withPayload(path(['match', 'params', 'id']))
      .withOptions({ dispatchOnMount: true }),
  getDomain: getDomain
      .withPayload(({ getVacancy }) => ({
        company: pathOr('arovana', ['data', 'company'], getVacancy),
        domain: compose(
            prop('id'),
            find(propEq('name', REFERRAL_DOMAIN)),
            pathOr([], ['data', '_relations', 'domains']),
        )(getVacancy)
      }))
      .withOptions({ dispatchOnUpdate: true })
})(RecommendedLanding);

import React, { Component } from 'react';

export default class NoMatch extends Component {

    render() {
        return <div className='no-match'>
            <h1>Page not found</h1>
        </div>;
    }
}
